import React from "react"

const PlaySvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112 112">
    <path
      d="M56,0 C25.1205161,0 0,25.1223226 0,56 C0,86.8776774 25.1205161,112 56,112 C86.8794839,112 112,86.8776774 112,56 C112,25.1223226 86.8794839,0 56,0 Z M79.2236364,57.4737572 L42.86,82.6741943 C42.5490909,82.8901981 42.1836364,83 41.8181818,83 C41.5309091,83 41.2436364,82.9315988 40.9781818,82.7965965 C40.3763636,82.4869911 40,81.8713804 40,81.1999688 L40,30.7990944 C40,30.1276828 40.3763636,29.5120721 40.9781818,29.2024667 C41.5781818,28.8946614 42.3036364,28.9396621 42.86,29.3248688 L79.2236364,54.525306 C79.7109091,54.8619118 80,55.4127214 80,55.9995316 C80,56.5863418 79.7109091,57.1371513 79.2236364,57.4737572 Z"
      id="play"
      fill="#fff"
      fillOpacity="0.9"
      fillRule="nonzero"
    />
  </svg>
)

export default PlaySvg
